<template>
  <PageTemplate>
		<Section>
			<Grid>
				<GridContainer size="4">
					<Card color="primary">
						<CardSection>
							<Stack direction="column" align="left" space="3">
								<Stack direction="column" align="left" space="1">
									<Heading size="2" whiteout>Office Location</Heading>
									<Heading size="6" whiteout>1234 Road St.</Heading>
									<Heading size="6" whiteout>Las Vegas, Nevada</Heading>
									<Heading size="6" whiteout>89002</Heading>
								</Stack>
								<Stack direction="column" align="left" space="1">
									<Heading size="2" whiteout>Contact Info</Heading>
									<Heading size="6" whiteout>Phone: 1 (234) 567-7890</Heading>
									<Heading size="6" whiteout>Toll Free: 1 (800) 567-7890</Heading>
									<Heading size="6" whiteout>Support: info@edisonmedia.com</Heading>
								</Stack>
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="8">
					<Heading size="2">Need help? <br/> Let us answer your questions.</Heading>
					<Form>
						<FormContainer size="12">
							<Input label="Name" />
						</FormContainer>
						<FormContainer size="6">
							<Input label="Email" />
						</FormContainer>
						<FormContainer size="6">
							<Input label="Phone" />
						</FormContainer>
						<FormContainer size="12">
							<Textarea label="Comment" />
						</FormContainer>
					</Form>
					<Button label="Send Message" color="primary" />
				</GridContainer>
			</Grid>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/edison/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
